<template>
  <v-card>
    <v-card-title class="justify-center mb-4">
      Buscar dados
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        @submit.native.prevent
      >
        <v-text-field
          v-model="dates"
          label="Período - dd/mm/aaaa"
          maxlength="21"
          outlined
          dense
          @keydown="$event => handleKeyDown($event)"
        >
          <template v-slot:append>
            <v-icon>{{ icons.mdiCalendar }}</v-icon>
          </template>
        </v-text-field>

        <v-autocomplete
          v-model="selectedEmployee"
          :items="employees"
          :item-value="data => getEmployeeData(data)"
          label="Colaboradores"
          item-text="name"
          clearable
          outlined
          dense
        />

        <v-select
          v-model="selectedCompanies"
          :items="sortedCompanies"
          deletable-chips
          item-text="name"
          item-value="id"
          label="Filial"
          multiple
          outlined
          clearable
          chips
          dense
        />

        <v-autocomplete
          v-model="selectedReportType"
          :rules="[rules.required]"
          :items="reportTypes"
          label="Tipo de relatório"
          clearable
          outlined
          dense
        />
      </v-form>

      <div class="d-flex justify-end mt-4">
        <v-btn
          color="error"
          class="mr-4"
          @click="closeModal(), resetForm()"
        >
          <span>Fechar</span>
        </v-btn>

        <v-btn
          color="info"
          @click="getData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar } from '@mdi/js'
import axios from 'axios'
import localStorageSlim from 'localstorage-slim'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      selectedEmployee: {},
      selectedReportType: '',
      selectedCompanies: null,

      dates: null,
      rawData: [],
      employees: [],
      reportTypes: ['Duplicata', 'Vale Funcionário'],

      companies: [
        { id: 3, name: 'Imperatriz' },
        { id: 4, name: 'Açailândia' },
        { id: 5, name: 'Grajaú' },
        { id: 6, name: 'Itinga do Maranhão' },
        { id: 7, name: 'Porto Franco' },
        { id: 8, name: 'Cidelândia' },
        { id: 9, name: 'Amarante do Maranhão' },
        { id: 10, name: 'Arame' },
        { id: 11, name: 'Senador La Roque' },
        { id: 12, name: 'Sitio Novo' },
        { id: 13, name: 'Formosa da Serra Negra' },
        { id: 14, name: 'Bom Jesus das Selvas' },
        { id: 16, name: 'FUJI' },
      ],

      isLoading: false,
      showCalendar: false,
      isCalendarDate: false,

      icons: { mdiCalendar },

    }
  },

  computed: {
    sortedCompanies() {
      return this.sortCompaniesByName()
    },
  },

  watch: {
    dates(newValue) {
      const LENGTHS_THAT_END_IN_SLASH = [2, 5, 13, 16]
      const LENGTH_THAT_ENDS_IN_COMMA = 10
      const LENGTH_THAT_TRIGGERS_SPLIT = 21

      if (!Array.isArray(this.dates)) {
        const { length } = newValue

        if (LENGTHS_THAT_END_IN_SLASH.includes(length)) {
          this.dates = `${newValue}/`
        }

        if (length === LENGTH_THAT_ENDS_IN_COMMA) {
          this.dates = `${newValue},`
        }

        if (length === LENGTH_THAT_TRIGGERS_SPLIT) {
          this.dates = this.dates.split(',')
        }
      }
    },
  },

  created() {
    this.employees = localStorageSlim.get('listEmployees', { decrypt: true })
  },

  methods: {
    // cSpell: disable
    async getDataCloud() {
      const {
        dates, getStartDate, getFinalDate, selectedCompanies, selectedEmployee,
      } = this
      const startDate = getStartDate(dates)
      const endDate = getFinalDate(dates)
      const cloudId = selectedEmployee?.cloud_id || null

      const body = {
        idrelatorioconfiguracao: 224,
        idrelatorioconsulta: 111,
        idrelatorioconfiguracaoleiaute: 224,
        idrelatoriousuarioleiaute: 1496,
        ididioma: 1,
        listaempresas: selectedCompanies,
        filtros: `ComDocumentoFiscal=True;
        Modalidadedecobranca=null;
        DocumentoCancelado=True;
        Datademovimentacaofinal=${endDate || '2060-12-31'}};
        Especie=null;
        Departamento=null;
        Municipio=null;
        Pessoa=${cloudId};
        Situacao=1;
        Portador=null;
        Datademovimentacaoinicial=${startDate || '2000-01-01'}};
        Receita=null;
        RelacaoComercial=6;
        Vendedor=null;
        Origem=null;
        SituacaoCobrancaBancaria=False;
        SomenteComProvisao=True;
        SomenteSemProvisao=True;
        QuantidadeParcelas=0`,
      }

      if (!selectedCompanies || selectedCompanies.length === 0) {
        body.listaempresas = this.getAllCompaniesId()
      }

      const headers = [
        {
          text: 'NOME', value: 'name', sortable: false, align: 'center',
        },
        {
          text: 'DOCUMENTO', value: 'document', sortable: false, align: 'center',
        },
        {
          text: 'EMISSÃO', value: 'emission', sortable: false, align: 'center',
        },
        {
          text: 'FILIAL', value: 'fantasyName', sortable: false, align: 'center',
        },
        {
          text: 'ABERTO', value: 'value', sortable: false, align: 'center',
        },
        {
          text: 'RECEBIMENTO', value: 'received', sortable: false, align: 'center',
        },
        {
          text: 'VENCIMENTO', value: 'dueDate', sortable: false, align: 'center',
        },
      ]

      const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNjRmYmM4MS0zOTY4LTQ3YjYtODkyZC04NWU0MDQ3MTVkNzAiLCJvcmciOiJvcmcwMDAxMjEifQ.VEDLqbRglJY-MWcwlOVCjO2BHtwxG9rYzUwz1z4EB4M'

      this.isLoading = true

      await axios
        .post('https://microworkcloud.com.br/api/integracao/terceiro', body, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(response => {
          this.rawData = response.data

          const data = response.data.map(({
            pessoa, cpfoucnpj, empresa, aberto, emissao, documento, recebimentos, vencimento,
          }) => ({
            id: Math.random(),
            name: pessoa,
            cpf: cpfoucnpj,
            value: aberto,
            dueDate: vencimento,
            fantasyName: empresa,
            emission: emissao,
            document: documento,
            received: recebimentos,
            type: 'duplicata',
          }))

          this.sendFilteredData(data, headers, this.rawData)
        })
        .catch(error => {
          this.showMessage({
            title: 'Erro ao enviar dados',
            text: error.message,
            icon: 'error',
          })
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    async getDataApiNexus() {
      const {
        dates, getStartDate, getFinalDate, selectedCompanies, selectedEmployee,
      } = this
      const startDate = getStartDate(dates)
      const endDate = getFinalDate(dates)

      this.isLoading = true

      const body = {
        employer_id: selectedEmployee?.id || 'null',
        company: selectedCompanies,
        initial_date: startDate || '2000-01-01',
        final_date: endDate || '2060-12-31',
      }

      if (!selectedCompanies || selectedCompanies.length === 0) {
        body.company = this.getAllCompaniesId()
      }

      const headers = [
        {
          text: 'NOME', value: 'name', sortable: false, align: 'center',
        },
        {
          text: 'CLOUD.ID', value: 'cloudId', sortable: false, align: 'center',
        },
        {
          text: 'HISTORICO', value: 'history', sortable: false, align: 'center',
        },
        {
          text: 'FILIAL', value: 'fantasyName', sortable: false, align: 'center',
        },
        {
          text: 'VALOR', value: 'value', sortable: false, align: 'center',
        },
        {
          text: 'MOVIMENTO', value: 'movementDate', sortable: false, align: 'center',
        },
        {
          text: 'VENCIMENTO', value: 'dueDate', sortable: false, align: 'center',
        },
      ]

      await axiosIns
        .post('api/v1/integration/typing_sheet/filter_vale_employee', body)
        .then(response => {
          this.rawData = response.data

          const data = response.data.map(
            ({
              name, cpf, cloud_id: cloudId, company: { fantasy_name: fantasyName }, vale: {
                vencimento, historico, valoradiantamento, datamovimento,
              },
            }) => ({
              id: Math.random(),
              name,
              cpf,
              cloudId,
              history: historico,
              dueDate: vencimento,
              value: valoradiantamento,
              movementDate: datamovimento,
              type: 'valeFuncionario',
              fantasyName,
            }),
          )

          this.sendFilteredData(data, headers, this.rawData)
        })
        .catch(error => this.showMessage({ title: 'Erro ao enviar dados', text: error.message, icon: 'error' }))
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    getData() {
      const isValid = this.$refs.form.validate()

      if (isValid) {
        if (this.selectedReportType === 'Duplicata') {
          this.getDataCloud()

          return
        }

        this.getDataApiNexus()

        return
      }

      this.showMessage({
        title: 'Formulario invalido',
        text: 'Verifique os campos de filial e tipo de relatório',
        icon: 'error',
      })
    },

    handleKeyDown(event) {
      const { key } = event
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']
      const isDigit = /^\d$/.test(key)

      if (!isDigit && !allowedKeys.includes(key)) {
        event.preventDefault()
      }
    },

    getAllCompaniesId() {
      const ordenedCompanies = this.companies.sort((a, b) => a.id - b.id)
      const allCompanies = ordenedCompanies.map(company => company.id)

      return allCompanies
    },

    /**
     * @returns {{ id: string; name: string; cloud_id: number; }}
     */
    getEmployeeData(data) {
      return { id: data?.id, name: data?.name, cloud_id: data?.cloud_id }
    },

    /**
     * @param {Object[]} data
     * @param {Object[]} rawData
     * @param {{ text: string; value: string; sortable: boolean; align: string; }} headers
     */
    sendFilteredData(data, headers, rawData) {
      this.$emit('filteredData', [data, headers, rawData])
    },

    /**
     * @param {string[]} dates
     */
    getStartDate(dates) {
      if (Array.isArray(dates) && dates.length) {
        dates.sort((fisrt, second) => new Date(fisrt) - new Date(second))
        const startDate = dates[0]
        const startDateFormatted = this.formatDate(startDate)

        return startDateFormatted
      }

      return dates
    },

    /**
     * @param {string[]} dates
     */
    getFinalDate(dates) {
      if (Array.isArray(dates) && dates.length) {
        dates.sort((fisrt, second) => new Date(fisrt) - new Date(second))
        const finalDate = dates[dates.length - 1]
        const finalDateFormatted = this.formatDate(finalDate)

        return finalDateFormatted
      }

      return dates
    },

    sortCompaniesByName() {
      return this.companies.sort((a, b) => a.name.localeCompare(b.name))
    },

    resetForm() {
      this.$refs.form.reset()
    },

    openCalendar() {
      this.dates = []
      this.showCalendar = true
    },

    /**
     * @param {string} date
     */
    formatDate(date) {
      if (typeof date === 'string') {
        const BrazilianDateRegex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/

        if (date.match(BrazilianDateRegex)) {
          const [day, month, year] = date.split('/')

          return `${year}-${month}-${day}`
        }

        return date
      }

      this.showMessage({ title: 'Formato de data inválido', text: 'tente novamente', icon: 'error' })

      throw new Error('invalid date format')
    },

  },
}
</script>

        // idrelatorioconfiguracao: 222,
        // idrelatorioconsulta: 109,
        // idrelatorioconfiguracaoleiaute: 222,
        // idrelatoriousuarioleiaute: 1120,
        // ididioma: 1,
        // listaempresas: selectedCompanies,
        // filtros: `DocumentoCancelado=True;
        //   Datademovimentacaoinicial=${startDate || '2000-01-01'}};
        //   SomenteComProvisao=True;
        //   Datademovimentacaofinal=${endDate || '2060-12-31'}};
        //   Receita=null;
        //   Vendedor=null;
        //   Operador=null;
        //   Portador=null;
        //   Especie=null;
        //   Modalidadedecobranca=null;
        //   Pessoa=${cloudId};
        //   Municipio=null;
        //   ContaFinanceira=null;
        //   Departamento=null;
        //   ComDocumentoFiscal=True;
        //   Origem=null;
        //   RelacaoComercial=null;
        //   LancamentosEstornados=False;
        //   SomenteSemProvisao=True;
        //   LancamentoTipoSistema=6,40,1,44,45,46,48,7,47,41,53,54,55,57`,
